import { ErrorTemplate } from 'components/error-template';
import { HTTP_CODES } from 'utils/constants';

export default function Custom404() {
  return <ErrorTemplate errorCode={HTTP_CODES.NOT_FOUND} />;
}

export async function getStaticProps() {
  return { props: {} };
}
